import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { NavService } from 'src/app/shared/services/nav.service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { ResponseModel } from '../../../../../core/models/response.model';
import { BillModel } from '../../../../../core/models/stock/bill.model';
import { StockItemModel } from '../../../../../core/models/stock/stock-item.model';
import { StockResourceModel } from '../../../../../core/models/stock/stock-resource.model';
import { PartyService } from '../../../../../core/services/api/company/party.service';
import { StockService } from '../../../../../core/services/api/stock/stock.service';
import { FapModalComponent } from '../../../../../shared/partials';
import { shareReplay, switchMap, tap } from 'rxjs/operators';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { StateService } from '../../../../../shared/services/state.service';
import { TranslateService } from '@ngx-translate/core';
import { UnitTypeService } from '../../../../../core/services/api/unit-type/unit-type.service';

export const DATE_TIME_FORMAT = {
  parse: {
    dateInput: 'MMM DD YYYY',
  },
  display: {
    dateInput: 'MMM DD YYYY',
    monthYearLabel: 'MM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
}

@Component({
    selector: 'fap-stock-items',
    templateUrl: './fap-stock-items.component.html',
    styleUrls: ['./fap-stock-items.component.scss'],
    providers: [{provide: NGX_MAT_DATE_FORMATS, useValue: DATE_TIME_FORMAT}]
})
export class FapStockItemsComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    public stockOverview: Array<StockItemModel> = [];
    public checkIds: Array<number> = [];
    public entries = [];
    public documents: Array<BillModel> = [];
    public currencyUnits = [];
    closeActive = new Subject<void>();
    public subscriptions: Array<Subscription> = [];
    public transactions : Array<any> = [];
    @ViewChild('docsModal')
    public docsModal: FapModalComponent;
    public limit = 20;
    public nextProductToken: { limit: number, offset: number; search?: string; } = null;
    public getMore = true;
    public minDate;
    public maxDate;
    public prodFilterForm: UntypedFormGroup
    public products: Array<any> = [];
    public translatedNames:any = [];
    public langString: string;
    @ViewChild('addEditProductModal')
    public addEditProductModal: FapModalComponent;
    @ViewChild('productsModal')
    public productsModal: FapModalComponent;
    public productId: number;
    @ViewChild('doc_search') public doc_search : HTMLElement;
    status = false;
    prodStatus = false;
    public showProducts = false;
    public showDocuments = false;
    public isSelectDialogOpen: boolean = false;
    public showDropdown = false;
    public documentTypes = [
      {key: 'invoice_in', value: 'Invoice in'},
      {key: 'invoice_out', value: 'Invoice out'},
      {key: 'internal_note', value: 'Internal note'},
      {key: 'production_note', value: 'Production note'},
      {key: 'estimation_note', value: 'Estimation note'},
    ];
    public pdfDoc;
    public excelDoc;
    private partyCache: { [id: number]: Observable<string> } = {};
    @ViewChild('stockTable') stockTable: ElementRef;
    @ViewChild('entriesTable') entriesTable: ElementRef;
    @ViewChild('transactionsTable') transactionsTable: ElementRef;
    @ViewChild('documentTable') documentTable: ElementRef;

    constructor(
        public globalRegistry: GlobalRegistryService,
        public stockService: StockService,
        public unitService: UnitTypeService,
        public router:Router,
        public activatedRoute: ActivatedRoute,
        public partyService: PartyService,
        public formBuilder: UntypedFormBuilder,
        public navService: NavService,
        public cacheService: CacheResolverService,
        public widgetService: WidgetsService,
        private stateService: StateService,
        private translate: TranslateService
    ) {
    }

    public minValueChanged(ev) {
      this.minDate = ev.target.value;
  }

  public maxValueChanged(ev) {
      this.maxDate = ev.target.value;
  }

  public dropdown() {
    this.showDropdown = !this.showDropdown;
  }

  getTitle(transaction: any): string {
    const baseTitle = this.translate.instant('stock.transactionsOn');
    const id = transaction?.id || '';
    const productName = transaction?.resource_entry?.product?.label?.name || '';
    return `${baseTitle} <span>${id}. ${productName}</span>`;
  }

  public getProducts() {
    const url = this.stockService.getUrl('products/');
    this.subscriptions.push(this.stockService.getProducts({ limit: this.limit }).subscribe(data => {
      this.cacheService.delete(url+'limit='+this.limit);
      console.log(data);
      this.products = data.body.results;
      this.nextProductToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
          if(this.nextProductToken) this.getMore = true
    }))
  }

    public productEdit(product) {
      this.productId = product.id
      this.addEditProductModal.showModal();
    }

    public showProductModal(): void {
      this.productId = -1;
      this.addEditProductModal.showModal();
    }

    filterProduct() {
      const url = this.stockService.getUrl('');
      const params = {
        search: this.prodFilterForm.get('search').value ? this.prodFilterForm.get('search').value : '',
      }
      if(this.prodFilterForm.get('type').value) { Object.assign(params, {type : this.prodFilterForm.get('type').value}) }
      this.subscriptions.push(this.stockService.getProducts(params).subscribe(data => {
        this.cacheService.delete(url+'search='+params.search)
        console.log(data);
        this.nextProductToken = data.body.next
              ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
              : null;
        this.products = data.body.results;
        if(this.nextProductToken) this.getMore = true
      }))
    }

    getTranslation(translation) {
      const t =this.translatedNames.filter(trans => {
        return trans.id === translation
      });
      if(t[0]) {
        if(t[0][this.langString] === null || t[0][this.langString] === '') {
          return translation
        } else {
          return t[0][this.langString];
        }
      } else {
        return translation
    }
    }

    paramsToObject(entries) {
      const result = {}
      for(const [key, value] of entries) {
        result[key] = value;
      }
      console.log(result);
      return result;
    }

    scrolledDownProducts() {
      console.log('scrolled');
      const params = this.nextProductToken;
      if(this.getMore) {
      this.nextProductToken && 
        this.subscriptions.push(this.stockService.getProducts(params).subscribe((data) => {
          console.log(data);
          if(this.products) {
            this.products = [...this.products, ...data.body.results];
          } else {
            this.products = data.body.results;
          }
          if(data.body.next == null) {
            this.getMore = false;
            return
          } else {
            const url = data.body.next.split('?')
            const urlParams = new URLSearchParams(url[1]);
            const entries = urlParams.entries();
            const params = this.paramsToObject(entries);
            console.log(params);
            if(this.nextProductToken.offset != params['offset']) {
            this.nextProductToken = {offset: params['offset'], limit: params['limit']};
            if(params['search']) this.nextProductToken.search = params['search'];
            } else {
              return
            }
          }
        }));
      } else {
        return
      }
    }

    refreshItems() {
      this.stockOverview = [];
      this.checkIds = [];
      this.entries = [];
      this.transactions = []
      const params = {refresh: 1}
      const url = this.stockService.getUrl('');
      this.cacheService.delete(url+'stock_items/refresh=1')
      this.stockService.getStockItems(params).subscribe(data => {
        this.stockOverview = data.model;
      })
    }

    public ngOnChanges(changes: SimpleChanges): void {
      if(Object.prototype.hasOwnProperty.call(changes, 'stockOverview') && this.stockOverview) {
            console.log(this.stockOverview)
              this.stockOverview.forEach((item) => {
                item.checked = this.stateService.getSelectedEntryIds().has(item.id);
              });
              
              this.fetchEntries();
              const resourceEntryId = this.stateService.getResourceEntryId()
              if(resourceEntryId) {
                this.getTransaction(resourceEntryId)
              }
              setTimeout(() => {
                this.stockTable.nativeElement.scrollTop = this.stateService.scrollPositions['stockTable'] || 0;
              }, 500);
      }
    }

    public ngOnInit(): void {
      console.log('Selected Entry IDs:', this.stateService.getSelectedEntryIds());
      this.navService.editFarm.next(false);
      this.navService.createMod.next(false);
      this.widgetService.setSize(12);
      this.widgetService.setMapResize(false);
      this.translatedNames = this.globalRegistry.systemData.translations;
      this.langString = localStorage.getItem('language');
        this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
        this.subscriptions.push(
            this.unitService.getUnitByGenre('$').subscribe((data) => {
                this.currencyUnits = data.model;
            })
        );
        this.subscriptions.push(this.activatedRoute.queryParams.subscribe(data => {
          console.log(data);
          if(data.farms) {
            console.log(data.farms.toString());
            const params = {
              locations: data.farms.toString()
            }
            this.stockService.getStockItems(params).subscribe(data => {
              this.stockOverview = data.body.results;
            })
          }
        }));
        this.globalRegistry.reloadParties();
    }

    hideProducts() {
      this.showProducts = false;
      this.productsModal.hideModal();
      this.router.navigate(['pages/stock']);
    }

    hideDocuments() {
      this.showDocuments = false;
      this.docsModal.hideModal();
      this.router.navigate(['pages/stock']);
    }

    onDivScroll(div, event: Event) {
      const divElement = event.target as HTMLElement;
      const scrollTop = divElement.scrollTop;
      this.stateService.scrollPositions[div] = scrollTop;
    }

    getMoment(date) {
      const dstring = moment.utc(date).local();
      return moment(dstring).fromNow();
    }

    selectEntry(event: any, item: any): void {
      item.checked ? this.stateService.addSelectedEntryId(item.id) : this.stateService.removeSelectedEntryId(item.id);
      this.fetchEntries();
    }
  
    fetchEntries(): void {
      const selectedEntryIds = Array.from(this.stateService.getSelectedEntryIds());
      if (selectedEntryIds.length > 0) {
        console.log(selectedEntryIds);
        this.subscriptions.push(
          this.stockService.getStockResourceEntry(selectedEntryIds).subscribe((data: ResponseModel<StockResourceModel[]>) => {
            this.entries = data.body.results;
            console.log(this.entries);
            setTimeout(() => {
              if (this.entriesTable && this.entriesTable.nativeElement) {
                this.entriesTable.nativeElement.scrollTop = this.stateService.scrollPositions['entriesTable'] || 0;
                console.log(this.stateService.scrollPositions['entriesTable'])
              }
            }, 1000);
            if (this.transactions.length > 0) {
              const bool = this.entries.some((value) => value.id == this.transactions[0].resource_entry.id);
              if (!bool) {
                this.transactions = [];
              }
            }
          })
        );
      } else {
        this.transactions = [];
        this.entries = [];
      }
    }

    public ngOnDestroy(): void {
      this.widgetService.setSize(6);
      this.widgetService.setMapResize(true);
        this.subscriptions.forEach((s) => {
            s.unsubscribe();
        });
    }

    public getTransaction(resourceEntryId) {
      this.stateService.setResourceEntryId(resourceEntryId);
      this.stockService.getTransactions(resourceEntryId).subscribe(data => {
        this.transactions = data.body.results;
        setTimeout(() => {
          this.transactionsTable.nativeElement.scrollTop = this.stateService.scrollPositions['transactionsTable'] || 0;
        }, 500)
        console.log(this.transactions);
      })
    }

    public goToBill(billId, billType) {
      this.router.navigate(['/pages/stock/bill/'+billId], {queryParams: {type: billType, new_doc: 'false'}})
    }

    public selectAll(event) {
      this.checkIds = [];
      if(event.target.checked === true) {
      this.stockOverview.forEach(element => {
        this.checkIds.push(element.id);
      });
    } else {
      this.checkIds = [];
    }
    }

    public refreshItem(stockItemId) {
      const params = { refresh: 1 }
      const url = this.stockService.getUrl('');
      this.cacheService.delete(url+'stock_items/'+ stockItemId +'/refresh=1');
      this.stockService.refreshStockItem(stockItemId, params).subscribe(data => {
        console.log(data);
        // this.stockOverview = data.body.results;
      });
      // this.refreshItems();
    }

    getPartyInfo(id) {
      // Check if the result is already in the cache
      if (this.partyCache[id]) {
        return this.partyCache[id];
      }
  
      // Check if the party exists in the systemData.parties array
      const existingParty = this.globalRegistry.systemData.parties.find(party => party.id === id);
      if (existingParty) {
        this.partyCache[id] = of(existingParty.name).pipe(shareReplay(1)); // Store the value in the cache as an observable
        return this.partyCache[id];
      } else {
        // Make the API call only when the value is not in the cache
        const party$ = this.partyService.getParty(id).pipe(
          switchMap(data => {
            const party = data.body.results;
            const partyExist = this.globalRegistry.systemData.parties.some(obj => obj.id === party.id);
            if (!partyExist) {
              this.globalRegistry.systemData.parties.push(party); // Add the party to the systemData.parties array
            }
            this.partyCache[id] = of(party.name).pipe(shareReplay(1)); // Store the value in the cache as an observable
            return this.partyCache[id];
          }),
          tap(() => {
            // Do nothing in the tap operator, just to trigger the API call
          }),
          shareReplay(1) // Cache and replay the API call result
        );
  
        this.partyCache[id] = party$; // Store the observable in the cache
        return party$;
      }
    }

    CheckAllOptions() {
      
      if (this.stockOverview.every(val => {
        this.checkIds.push(val.id);
        return val.checked == true
      }))
        this.stockOverview.forEach(val => {
          this.checkIds = [];
          return val.checked = false 
        });
      else
        this.stockOverview.forEach(val => {
          this.checkIds.push(val.id);
          return val.checked = true
        });
        
        setTimeout(() => {
          this.checkIds.filter((item,
            index) => this.checkIds.indexOf(item) === index)
          this.fetchEntries();
        }, 1500);
    }

    public toggleDocFilter() {
      this.status = !this.status;
    }

    public toggleProdFilter() {
      this.prodStatus = !this.prodStatus;
    }

    public exportAsPDF(doc) {
      this.router.navigate(['pages/stock/pdf/'+doc.id], {queryParams: {type: doc.type}});
    }

    public exportAsExcel(docId) {
      console.log(docId);
    }

}
