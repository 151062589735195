import { PrecisionService } from './precision/precision.service';
import { NotesService } from './farm/notes.service';
import {
    NgModule,
    ModuleWithProviders
} from '@angular/core';
import { AuthService } from './auth/auth.service';
import { UserService } from './user/user.service';
import { SystemDataService } from './system/system.service';
import { FarmService } from './farm/farm.service';
import { CompanyService } from './company/company.service';
import { CropService } from './crop/crop.service';
import { LotService } from './farm/lot.service';
import { FieldService } from './farm/field.service';
import { ActivityService } from './activity/activity.service';
import { PartyService } from './company/party.service';
import { TypesService } from './types/types.service';
import { StockService } from './stock/stock.service';
import { EquipmentService } from './equipment/equipment.service';
import { WidgetsService } from './widgets/widgets.service';
import { PeopleGroupService } from './people-group/people-group.service';
import { SensorService } from './sensor/sensor.service';
import { IngredientService } from './ingredient/ingredient.service';
import { CropTypeIngredientService } from './crop/crop-type-ingredient.service';
import { ForecastService } from './forecast/forecast.service';
import { AttributeService } from './attribute/attribute.service';
import { ObjectService } from './objects/object.service';
import { UnitTypeService } from './unit-type/unit-type.service';
import { OverviewService } from './overview/overview.service';
import { ReportsService } from './reports/reports.service';
import { BillingService } from './billing/billing.service';
import { H2oService } from './h2o/h2o.service';
import { AlergotelService } from './alergotel/alergotel.service';
import { TimezoneService } from './timezone/timezone.service';
import { EntityService } from './entity/entity.service';

@NgModule({
    imports: [
    ],
    declarations: [
    ]
})
export class ApiServicesModule {
    public static forRoot(): ModuleWithProviders<ApiServicesModule> {
        return {
            ngModule: ApiServicesModule,
            providers: [
                AuthService,
                UserService,
                SystemDataService,
                FarmService,
                CropService,
                LotService,
                FieldService,
                CompanyService,
                ActivityService,
                TypesService,
                StockService,
                PartyService,
                EquipmentService,
                WidgetsService,
                ObjectService,
                UnitTypeService,
                PeopleGroupService,
                SensorService,
                IngredientService,
                CropTypeIngredientService,
                NotesService,
                ForecastService,
                PrecisionService,
                UnitTypeService,
                AttributeService,
                OverviewService,
                ReportsService,
                BillingService,
                H2oService,
                AlergotelService,
                TimezoneService,
                EntityService
            ]
        };
    }
}
