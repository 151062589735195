import {
    Component, EventEmitter, Input, OnChanges, Output, SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { UnitInterface } from '../../../../core/interfaces/unit/unit.interface';
import { ResponseModel } from '../../../../core/models/response.model';
import { UnitTypeModel, UnitTypeService } from '../../../../core/services/api/unit-type/unit-type.service';

@Component({
    selector: 'fap-add-edit-unit',
    templateUrl: './fap_add-edit-unit.component.html',
    styleUrls: ['./fap_add-edit-unit.component.scss']
})
export class FapAddEditUnitComponent implements OnChanges{

    @Input()
    public selectedUnit: UnitTypeModel;

    @Input()
    public parentUnit: UnitTypeModel;

    @Input()
    public units: UnitTypeModel[];

    @Output()
    public unitActionCompleted: EventEmitter<number> = new EventEmitter();

    @Output()
    public cancel: EventEmitter<any> = new EventEmitter();

    public addEditUnitForm: UntypedFormGroup;

    constructor(private formBuilder: UntypedFormBuilder,
                private translateService: TranslateService,
                private toastyService: ToastrService,
                private unitService: UnitTypeService,
                public globalRegistry: GlobalRegistryService) {
        this.initAddEditUnitForm();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if(Object.prototype.hasOwnProperty.call(changes, 'selectedUnit')) {
            this.initAddEditUnitForm();
        }
    }

    public ifAddEditUnitsFormControlHasError(controlName: string, validationType: string): boolean {
        const control: any = this.addEditUnitForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    public submit(): void {

        if(this.addEditUnitForm.dirty) {
            const unit: UnitInterface = {
                name: this.addEditUnitForm.controls['name'].value,
                shortName: this.addEditUnitForm.controls['shortName'].value,
                genre: this.addEditUnitForm.controls['genre'].value,
                parent: this.addEditUnitForm.controls['parent'].value,
            };

            if(typeof this.addEditUnitForm.controls['parent'].value !== 'string') {
                unit.parent = this.addEditUnitForm.controls['parent'].value === 'new' ?
                    null :
                    this.addEditUnitForm.controls['parent'].value;
            }

            if(this.selectedUnit) {
                this.updateUnit({...{id: this.selectedUnit.id}, ...unit});
            }
            else {
                this.addUnit(unit);
            }
        }
    }

    private initAddEditUnitForm(): void {
        this.addEditUnitForm = this.formBuilder.group({
            name: [this.selectedUnit ? this.selectedUnit.name : '', Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(100)
                ])
            ],
            shortName: [this.selectedUnit ? this.selectedUnit.shortName : '', Validators.compose([
                    Validators.required,
                    Validators.minLength(1),
                    Validators.maxLength(100)
                ])
            ],
            parent: [this.selectedUnit && this.parentUnit ? this.parentUnit.id : '', Validators.compose([
                    Validators.required
                ])
            ],
            genre: [this.selectedUnit && this.selectedUnit.genre ? this.selectedUnit.genre : '', Validators.compose([
                Validators.required
            ])
        ]
        });
    }

    private addUnit(unit: UnitInterface): void {
        this.unitService.addUnit(unit).subscribe((response: ResponseModel<UnitTypeModel>): void => {
            this.toastyService.success(this.translateService.instant('units.unitAddSuccessfull'));
            this.globalRegistry.reloadUnits();
            this.unitActionCompleted.emit(response.model.id);
        },
        (): void => {
            this.toastyService.error(this.translateService.instant('units.unitAddError'));
        });
    }

    private updateUnit(unit: UnitInterface): void {
        this.unitService.updateUnit(unit).subscribe((): void => {
            this.toastyService.success(this.translateService.instant('units.unitUpdateSuccessfull'));
            this.globalRegistry.reloadUnits();
            this.unitActionCompleted.emit(this.selectedUnit.id);

        },
        (): void => {
            this.toastyService.error(this.translateService.instant('units.UnitUpdateError'));
        });
    }
}
