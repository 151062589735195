import { FapBaseModel } from '../../base/fap-base-model';
import { UnitTypeModel } from '../../services/api/unit-type/unit-type.service';

export class IngredientModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'quantity',
        'min',
        'max',
        'info',
        'sub_type',
        'type',
        'value',
        'unit'
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: {} = {

    };

    public id: number;
    public type: number;
    public quantity: string;
    public min: number;
    public max: number;
    public info: string;
    public subType: string;
    public value: number;
    public unit: UnitTypeModel
}
