<fap-header *ngIf="showHeader">
    <div class="d-flex align-items-center">
        <div class="tree_combo mr-3">
            <fap-tree-combo></fap-tree-combo>
          </div>
        <div class="add_btn mr-2">
            <fap-round-button [backgroundColor]="'tertiary'" (clicked)="showModal()"></fap-round-button>
        </div>
        <div class="add_circle mr-3" (click)="showConfigModal()">
            <div class="rounded-circle r config"></div>
        </div>
        <fap-search-filter
            [isSearchEnabled]="true"
>       </fap-search-filter>
    </div>
</fap-header>
<div class="container-fluid devices_page" id="parent">
    <div class="loader_container" *ngIf="showHeader">
        <span *ngIf="isLoading" class="loader">
            <i></i>
          </span>
    </div>
    <div class="devices_table" *ngIf="!isLoading" #deviceContainer
    [ngClass]="{ 'page': showHeader === true }"
    style="flex: auto;">
        <table *ngIf="deviceColumns && deviceColumns.length">
            <thead>
                <tr>
                    <th *ngFor="let head of tableHeaderLabels" [innerHTML]="head.name"></th>
                    <th *ngFor="let head of deviceColumns; let i = index"
                        resizable 
                        (widthChange)="onResize($event, head.sensor_ref.id)" 
                        (resizeEnd)="onResizeEnd(head.sensor_ref.id)"
                        (click)="changeUiSort(head)"
                        >
                        <div class="th_container" [ngStyle]="{'width': head.ui_colsize}">
                            <div class="name_part">
                                {{ head?.name ? head?.name : '' }}
                            </div>
                            <div class="absolut_part">
                                <div class="order">
                                    <ng-container *ngIf="head.ui_sortorder > 0 && hasMultipleSortOrders()">
                                        <span class="order_number">
                                        <div class="custom_entry">{{ head.ui_sortorder }}</div>
                                        <div class="custom_entry hov" (click)="cancelOrder(head); $event.stopPropagation();">
                                            <span class="small_icon">
                                                <i class="fa fa-times"></i>
                                            </span>
                                        </div>
                                        </span>
                                    </ng-container>
                                </div>
                                <div class="priority">
                                    <ng-container *ngIf="head.ui_sort === 1">
                                        <span class="small_icon">
                                        <div class="custom_entry">
                                            <i class="fa fa-caret-up"></i>
                                        </div>
                                        </span>
                                    </ng-container>
                                    
                                    <ng-container *ngIf="head.ui_sort === 2">
                                        <span class="small_icon">
                                        <div class="custom_entry">
                                            <i class="fa fa-caret-down"></i>
                                        </div>
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of deviceData">
                    <td [ngClass]="showHeader ? 'bg_white' : bgColor">
                        <div class="boxx" (click)="editDevice(data.id)">
                            <div class="img">
                                <ng-container *ngIf="data?.product">
                                    <fap-image 
                                        *ngIf="data?.product.images.length"
                                        [src]="mediaUrl + data?.product.images[data?.product.images.length - 1]?.file" 
                                        [isDeleteable]="false"
                                        [containerHeight]="63"
                                    >
                                    </fap-image>
                                    <fap-image 
                                        *ngIf="!data?.product"
                                        src="../../../../../../assets/images/image_placeholder.png" 
                                        [isDeleteable]="false"
                                        [containerHeight]="63"
                                    >
                                    </fap-image>
                                </ng-container>
                                <ng-container *ngIf="!data?.product">
                                    <fap-image 
                                        *ngIf="!data?.product"
                                        src="../../../../../../assets/images/image_placeholder.png" 
                                        [isDeleteable]="false"
                                        [containerHeight]="63"
                                    >
                                    </fap-image>
                                </ng-container>
                                <!-- <img src="../../../../../../assets/images/landscape_placeholder.png" alt="" class="icon" *ngIf="!data?.product"> -->
                            </div>
                            <div class="text">
                                <b [innerHTML]="data?.name ? data?.name : data?.product ? data?.product?.label?.name : '<i>No name</i>'"></b>
                                <div (click)="navigateToDeviceDashboard(data.id)" class="link">{{ data?.id }}</div>
                                <span class="lot_names">{{ [globalRegistry.systemData.lots, data?.lots] | getLotNames }}</span>
                            </div>
                        </div>
                    </td>
                    <td>{{ data?.last_transmission ? (data?.last_transmission | timeAgo) : '-' }}</td>
                    <td *ngFor="let sensor of data?.streams; let i = index;" [ngStyle]="getCellStyles(sensor)">
                        <div *ngIf="sensor" [innerHTML]="getExtraFieldsById(sensor, deviceColumns[i]?.sensor_ref?.unit_type?.short_name, data?.id)" (click)="handleButtonClick(data?.id, sensor)">
                            {{ sensor?.id }}
                        </div>
                        <div *ngIf="!sensor?.id">
                            -
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <fap-pagination
        [count]="count"
        [pageSize]="limit"
        (emitNextPrev)="handlePagination($event)"
    ></fap-pagination>
</div>

<fap-modal
    [inputIsLarge]="true"
    #deviceModal
    [titleColor]="'#ffffff'"
    [inputTitle]="'devices.addNewDevice' | translate"
    [showFooter]="false"
>
    <div>
        <form [formGroup]="deviceForm">
            <div class="form-group m-0">
                <div class="row align-items-baseline">
                    <div class="w-100">
                        <div class="input-group w-100">
                            <div class="col-6" (click)="getResourceEntries()">
                                <mat-form-field>
                                    <mat-label>{{"stock.entry" | translate}}</mat-label>
                                    <mat-select formControlName="resource_entry" name="resource_entry" required (selectionChange)="setEntry($event)" (infiniteScroll)="scrolledDown()" msInfiniteScroll>
                                        <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #typeFilter4 placeholder="Search">
                                        </mat-form-field>
                                    </div>
                                    <div class="scroll_wrap" (scroll)="onScroll($event)">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option class="noteIcons" *ngFor="let entry of resourceEntries | filter : typeFilter4.value" [value]="entry.id">
                                            <span class="mat-option-text">
                                                <div class="name clearfix d-flex no_bg full_width">
                                                    <div class="nme">
                                                          {{ entry?.product?.label?.name }}
                                                    </div> 
                                                    <div class="amt d-flex no_bg">
                                                        <div class="quty">
                                                            {{ entry?.remains_quantity | number: '1.0-2'}}
                                                        </div> 
                                                        <div class="unt">
                                                            <div>
                                                               <b>{{entry?.product | getProductUnit: globalRegistry?.systemData?.unitsMap}}</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                        </mat-option>
                                    </div>
                                    </mat-select>
                                    <mat-error *ngIf="deviceForm.controls['resource_entry'].errors?.required">
                                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                    </mat-error>
                                </mat-form-field>
                        </div>
                        <div class="col-6 d-flex nn posrltv custom_border">
                            <div class="colq">
                                <div class="input-group w-100">
                                    <mat-form-field>
                                        <mat-label>{{'stock.ingredients.ingredientQuantity' | translate}}</mat-label>
                                        <input type="number" style="text-align: right;" matInput formControlName="quantity" [max]="max">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="colu" style="padding: 0; width: 95px;" *ngIf="entry && entry.unit">
                                <div class="inlin">
                                    <b>{{entry?.product | getProductUnit: globalRegistry?.systemData?.unitsMap}}</b>
                                </div>
        
                            </div>
                            <div class="abs error" *ngIf="entry && !entry.unit">
                                <span>{{'stock.specifyProductUnit' | translate}}</span>
                            </div>
                        </div>
                            
                            </div>
                            <div class="col-12">
                                <chips 
                                    [items]="globalRegistry.systemData.lots" 
                                    [selectedItems]="selectedItems"
                                    [label]="'activities.lots' | translate"
                                    (updatedItems)="updateItems($event)"
                                    [addEnabled]="false"
                                >
                                </chips>
                            </div>
                            <div class="col mt-2 d-flex justify-content-end pb-4">
                                <button class="btn mr-2" (click)="addDevice()">{{ "translations.save" | translate }}</button>
                                <button class="btn" (click)="deviceModal.hideModal()">{{ "translations.cancel" | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
        </form>
    </div>
</fap-modal>

<fap-modal
    [inputIsLarge]="true"
    [showFooter]="false"
    #configModal
    [titleColor]="'#ffffff'"
    inputTitle="Config"
    (outputOnHide)="reroute()"
>
    <div class="config_block">
        <form [formGroup]="sensorForm" class="row">
            <div class="col-md-12">
                <fap-add-edit-delete-select 
                    [inputName]="'sensor.sensorGroup' | translate"
                    class="w-100"
                    [formControl]="sensorForm.controls['sensorGroup']"
                    name="group"
                    [inputIsRequired]="true"
                    [apiSearchEnabled]="true"
                    [inputValueFieldName]="'id'"
                    [inputNameFieldName]="'name'"
                    [inputNameTFieldName]="'nameT'"
                    [inputSelectValues]="sensorGroupTypes"
                    [inputConfirmDeleteText]="'sensor.deleteSensorGroupMessage' | translate"
                    (selectionChange)="changeGroup($event)"
                    (outputOnCreate)="showSensorGroupTypeModal(); closeCombo = false;"
                    (outputOnEdit)="sensorGroupTypeEdit($event); closeCombo = false;"
                    (outputOnDelete)="sensorGroupTypeDelete($event);"
                    [closeCombo]="closeCombo"
                    >
                </fap-add-edit-delete-select>
            </div>
            <mat-form-field class="col-md-5 col-sm-4">
                <mat-label>{{ "sensor.selectColumns" | translate }}</mat-label>
                <mat-select #matSelect required formControlName="sensorIds" (selectionChange)="onOptionSelected($event)">
                  <mat-select-trigger>
                    {{ 'Select items' }}
                  </mat-select-trigger>
                  <mat-form-field [floatLabel]="'never'" appearance="fill" class="w-100">
                    <input matInput #farmFilter placeholder="Search" />
                  </mat-form-field>
                  <div class="scroll_wrap">
                    <mat-option style="height: 0!important;"></mat-option>
                    <div class="slct">
                      <mat-option
                        *ngFor="let column of allDeviceColumns | filter : farmFilter.value"
                        [value]="column?.sensor_ref?.id"
                        [ngClass]="{'highlighted': column.highlighted}"
                        style="text-transform: capitalize"
                        (click)="$event.preventDefault();"
                      >
                      <span class="middle_text d-flex no_bg align-items-center">
                            <span class="small_text">{{ column?.sensor_ref?.id }}.</span>
                            <div class="device_name">
                                <span>{{ column?.name }}</span>
                                <span class="small_text" style="display: block;">
                                    {{ column?.sensor_ref?.unit_type?.short_name }}
                                </span>
                            </div>
                        </span>
                      </mat-option>
                    </div>
                  </div>
                </mat-select>
              </mat-form-field>
          
            <div class="btns-group">
                <button class="btn" (click)="selectAllItems()"><img src="../../../../../../assets/images/select_all.png" alt=""></button>
                <button class="btn" (click)="removeAllItems()"><img src="../../../../../../assets/images/remove_all.png" alt=""></button>
            </div>
          </form>                   

        <div class="form-group">
            <mat-list (dragover)="allowDrop($event)" (drop)="onDrop($event)">
                <mat-list-item
                    *ngFor="let column of deviceColumns; let idx = index"
                    style="text-transform: capitalize"
                    draggable="true"
                    (dragstart)="onDragStart($event, idx)"
                >
                <span class="small_text">{{ column?.sensor_ref?.id }}.</span>
                <span class="middle_text d-flex align-items-center">
                    {{column?.name}}
                  <span class="small_text pt-0" style="display: block; padding-left: 5px;">{{ column?.sensor_ref?.unit_type?.short_name }}</span>
                    </span>
                    <span class="mr-1 ml-auto" style="text-align: right; display: flex">
                    <span class="mr-1 ml-auto btn config_btn" *ngIf="selectedWidgetType === 'sensor_value' || selectedWidgetType === 'image' || selectedWidgetType === 'images_list'" (click)="editCss(column)">{{ 'widget.config' | translate }}</span>
                        <em class="material-icons pt-1 theme-color" (click)="removeItem(column?.sensor_ref?.id)">delete</em>
                    </span>
                </mat-list-item>
            </mat-list>
        </div>

        <div class="btn-group d-flex justify-content-end">
            <button class="btn mr-2" (click)="addColumns()">{{ 'translations.save' | translate }}</button>
            <button class="btn" (click)="configModal.hideModal()">{{ 'translations.cancel' | translate }}</button>
        </div>
    </div>
</fap-modal>

<fap-modal #addEditSensorGroupTypeModal
    [inputPrimaryButtonInterface]="addEditSensorGroupTypeModalButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="addEditSensorGroupTypeModalButtonSecondaryInterface"
    [inputTitle]="'sensor.addEditSensorGroupType' | translate">
    <form [formGroup]="sensorGroupTypeForm" class="col-12 p-0">
        <div class="form-group">
            <mat-form-field class="w-100" (click)="addEditTranslation('notes.type_name')">
                <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
                <input matInput formControlName="name" readonly #nameInput>
                <mat-error *ngIf="sensorGroupTypeForm.controls['name'].errors?.required">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label">{{ 'notes.color' | translate }}</mat-label>
                <input class="w-100" matInput type="color" formControlName="color">
                <mat-error *ngIf="sensorGroupTypeForm.controls['color'].errors?.required">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</fap-modal>

<fap-modal #addEditTranslationsModal [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.sensorGroupType)" 
        [prefix]="'SENSOR_GROUP_'" 
        [label]="label"
        [translation]="translation"
        [group]="'types'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #mapModal [showFooter]="false" [inputTitle]="'widget.location' | translate" [inputIsMediumLarge]="true">
    <div class="form-group map-container">
        <div
          class="search"
          [ngClass]="{ opened: mapSearchToggle, 'd-flex': mapTypeControl }"
        >
          <label for="search" class="fa fa-search" (click)="mapSearchToggle = true"> </label>
            <input
              matInput
              (focus)="mapSearchToggle = true"
              (focusout)="mapSearchToggle = false"
              (keydown.enter)="$event.preventDefault()"
              placeholder="search for location"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="off"
              type="text"
              class="form-control"
              #search
              [formControl]="searchControl"
            />
          
        </div>
        <agm-map
          [latitude]="currentCenter?.lat"
          [longitude]="currentCenter?.lng"
          [zoom]="zoom"
          [disableDefaultUI]="false"
          [zoomControl]="false"
          [scaleControl]="false"
          [streetViewControl]="false"
          [mapTypeControl]="false"
          [mapTypeId]="'hybrid'"
          (mapClick)="mapClicked($event)"
          (mapReady)="mapReady($event)"
        >
          <agm-marker
            *ngFor="let m of markers; let i = index"
            [latitude]="m.lat"
            [longitude]="m.lng"
            [label]="m.label"
            [markerDraggable]="m.draggable"
            [iconUrl]="m.iconUrl"
            [animation]="'DROP'"
            (dragEnd)="markerDragEnd(m, $event)"
            class="small_ico"
          >
            <!-- [animation]="'DROP'"  -->
      
            <agm-info-window>
              <strong>{{ m.content }}</strong>
            </agm-info-window>
          </agm-marker>
        </agm-map>
      </div>
</fap-modal>