import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { combineLatest, Subscription } from "rxjs";
import { CacheResolverService } from "src/app/core/services/api/cache/cache-resolver.service";
import { GlobalRegistryService } from "../../../../../core/global-registry/global-registry.service";
import { MapHelper } from "../../../../../core/heplers/map.helper";
import { FarmModel } from "../../../../../core/models/farm/farm.model";
import { LotModel } from "../../../../../core/models/lot/lot.model";
import { NoteModel } from "../../../../../core/models/notes/note.model";
import { ResponseModel } from "../../../../../core/models/response.model";
import { SensorModel } from "../../../../../core/models/sensor/sensor.model";
import { WidgetModel } from "../../../../../core/models/widget/widget.model";
import { CompanyService } from "../../../../../core/services/api/company/company.service";
import { LotService } from "../../../../../core/services/api/farm/lot.service";
import { NotesService } from "../../../../../core/services/api/farm/notes.service";
import { DeviceModel, ObjectModel, ObjectService } from "../../../../../core/services/api/objects/object.service";
import { SensorService } from "../../../../../core/services/api/sensor/sensor.service";
import { StockService } from "../../../../../core/services/api/stock/stock.service";
import { UnitTypeModel, UnitTypeService } from "../../../../../core/services/api/unit-type/unit-type.service";
import { WidgetsService } from "../../../../../core/services/api/widgets/widgets.service";
import { MapPolygonInterface } from "../../../../../shared/layout/fap_main-map/data/map-polygon.interface";
import { MapService } from "../../../../../shared/layout/fap_main-map/service/map-service";
import { TypesService } from "../../../../../core/services/api/types/types.service";
import { ActivityTypeModel } from "../../../../../core/models/activity/activity-type.model";
import { ActivityService } from "../../../../../core/services/api/activity/activity.service";
import { NavService } from "../../../../../shared/services/nav.service";
import { WidgetTypeModel } from "../../../../../core/models/type/widget-type.model";
import { EntityService } from "../../../../../core/services/api/entity/entity.service";

interface UpdateWidget {
  id: number;
  widget;
}
@Component({
  selector: "dashboard-container",
  templateUrl: "./dashboard-container.component.html",
  styleUrls: ["./dashboard-container.component.scss"],
})
export class DashboardContainerComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription> = [];
  public widgets: Array<WidgetModel> = [];
  public lots: Array<LotModel> = [];
  public sensors: Array<SensorModel> = [];
  public notes: Array<NoteModel> = [];

  public filterFarmIds: Array<number> = [];
  public filterLotIds: Array<number> = [];

  public filteredFarms: Array<FarmModel> = [];
  public filteredLots: Array<LotModel> = [];
  public filteredWidgets: Array<WidgetModel> = [];

  public objectTypes: Array<ObjectModel> = [];
  public unitTypes: Array<UnitTypeModel> = [];
  public activityTypes: Array<ActivityTypeModel> = [];
  public devices: Array<DeviceModel> = [];
  public isSelectDialogOpen: boolean = false;
  public widgetTypes: Array<WidgetTypeModel> = [];
  public sensorGroupTypes = [];
  public allDeviceColumns = [];
  public profiles = [];
  public formTypes = [];

  constructor(
    private widgetsService: WidgetsService,
    private ObjectService: ObjectService,
    private unitTypeService: UnitTypeService,
    private notesService: NotesService,
    private activatedRoute: ActivatedRoute,
    private mapService: MapService,
    private globalRegistry: GlobalRegistryService,
    private lotService: LotService,
    private sensorService: SensorService,
    public toastr: ToastrService,
    public translateService: TranslateService,
    public router: Router,
    public stockService: StockService,
    public companyService: CompanyService,
    public cacheService: CacheResolverService,
    public typeService: TypesService,
    public activityService: ActivityService,
    public navServices: NavService,
    private objectService: ObjectService,
    private entityService: EntityService
  ) {
    localStorage.setItem(
      "access",
      JSON.stringify({
        lots: [1, 1, 1],
        people: [1, 1, 1],
        equipments: [1, 1, 1],
        notes: [1,1,1]
      })
    );
    const localQueryParams = localStorage.getItem("queryParams")
      ? JSON.parse(localStorage.getItem("queryParams"))
      : {};
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: localQueryParams,
    });
    this.subscriptions.push(
      combineLatest([
        this.lotService.getLots(),
        this.widgetsService.getWidgets(),
        this.ObjectService.getObjectTypes(),
        this.unitTypeService.getUnits(),
        this.ObjectService.getDevices(),
        this.typeService.getActivityTypes(),
        this.typeService.getProfiles({type: 'sensors'}),
        this.typeService.getWidgetTypes(),
        this.activatedRoute.queryParams,
      ]).subscribe(
        ([lots, widgets, objectTypes, unitTypes, devices, activityTypes, sensorGroupTypes,
          widgetTypes, 
          queryParams]: [
          ResponseModel<LotModel[]>,
          ResponseModel<WidgetModel[]>,
          ResponseModel<ObjectModel[]>,
          ResponseModel<UnitTypeModel[]>,
          ResponseModel<DeviceModel[]>,
          ResponseModel<ActivityTypeModel[]>,
          ResponseModel<any[]>,
          ResponseModel<WidgetTypeModel[]>,
          Params
        ]): void => {
          this.widgets = widgets.model;
          this.lots = lots.model;
          this.objectTypes = objectTypes.model;
          this.unitTypes = unitTypes.model;
          this.devices = devices.model
          this.activityTypes = activityTypes.model;
          this.sensorGroupTypes = sensorGroupTypes.body.results;
          this.widgetTypes = widgetTypes.model;
          this.filterFarmIds = [];
          this.filterLotIds = [];

          if (queryParams["farms"] !== undefined) {
            if (Array.isArray(queryParams["farms"])) {
              this.filterFarmIds = queryParams["farms"].map(
                (farmId: string): number => Number(farmId)
              );
            } else {
              this.filterFarmIds = [Number(queryParams["farms"])];
            }
          }

          if (queryParams["lots"] !== undefined) {
            if (Array.isArray(queryParams["lots"])) {
              this.filterLotIds = queryParams["lots"].map(
                (lotId: string): number => Number(lotId)
              );
            } else {
              this.filterLotIds = [Number(queryParams["lots"])];
            }
          }
          this.filteredFarms = this.getFilteredFarms(
            this.filterFarmIds,
            this.filterLotIds
          );
          this.filteredLots = this.getFilteredLots(
            this.filterFarmIds,
            this.filterLotIds
          );
          this.initMap();
          this.loadWidgets();
        }
      )
    );
  }

  getProfiles() {
    let url = this.widgetsService.getUrl('ui_profiles/');
    this.widgetsService.getProfiles().subscribe(data => {
      this.cacheService.delete(url);
      this.profiles = data.results;
    })
  }

  getSensorGroupTypes() {
      const url = this.typeService.getUrl('profiles/');
      this.typeService.getProfiles({type: 'sensors'}).subscribe(data => {
        this.cacheService.delete(url);
        this.sensorGroupTypes = data.body.results;
      })
  }

  getAllDeviceDataColumns() {
    this.objectService.getAdvDeviceDataColumns({filter: 'all'}).subscribe(data => {
    this.allDeviceColumns = data.body.results;
    })
  }

  public drillData(data): void {
    console.log(data);
    const { sensor, start, aggBy, end }: { sensor: number, start: any, aggBy: string, end: any } = data;
    console.log('Original start:', start);
    console.log('Original end:', end);
    
    const isISODateString = (dateString: string): boolean => {
      const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
      return isoRegex.test(dateString);
    };

    const formatLocalISOString = (date: Date, endOfDay: boolean = false): string => {
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      let hours = ('0' + date.getHours()).slice(-2);
      let minutes = ('0' + date.getMinutes()).slice(-2);
      let seconds = ('0' + date.getSeconds()).slice(-2);
      let milliseconds = ('00' + date.getMilliseconds()).slice(-3);

      if (endOfDay) {
          hours = '23';
          minutes = '59';
          seconds = '59';
          milliseconds = '999';
      }

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    };

    const checkAndFormatDate = (date: any, endOfDay: boolean = false): string => {
      if (typeof date === 'string' && isISODateString(date)) {
          return date;
      } else if (date instanceof Date) {
          const formattedDate = formatLocalISOString(date, endOfDay);
          return formattedDate;
      } else {
          return date;
      }
    };

    const formattedStart = checkAndFormatDate(start);
    const formattedEnd = checkAndFormatDate(end, true);

    // Set values in sensor service
    this.sensorService.setName(true);
    this.sensorService.setFromDate(formattedStart);
    this.sensorService.setToDate(formattedEnd);
    this.sensorService.setAgg(aggBy);
    this.sensorService.drill.next({
        sensor: sensor,
        from: formattedStart,
        agg_by: aggBy,
        to: formattedEnd,
        compare: 0
    });
}

  ngOnInit(): void {
    this.widgetsService.setSize(12);
    this.mapService.hideMap();
    this.subscriptions.push(this.sensorService
      .getSensors()
      .subscribe((sensors: ResponseModel<SensorModel[]>): void => {
        this.sensors = sensors.model;
      }));
      this.getNotes();
      this.widgetsService.getSize.subscribe(data => {
        if(data != 12) {
          this.mapService.showMap();
        } else {
          this.mapService.hideMap();
        }
      });
      this.widgetsService.getDCoords.subscribe(data => {
        console.log(data);
      }); 
      this.getProfiles();
      this.getAllDeviceDataColumns();
      this.getFormTypes();
  }

  ngOnDestroy(): void {
    this.widgetsService.setMapResize(true);
    this.widgetsService.setDCoords([]);
    this.mapService.mapPolygons = [];
    this.mapService.showButtons = false;
  }

  public getNotes(): void {
    this.notesService
      .getNotes()
      .subscribe(
        (notes: ResponseModel<NoteModel[]>) => {this.notes = notes['results']}
      );
  }

  public addWidget(widget): void {
    this.widgetsService.addWidget(widget).subscribe(
      (): void => {
        this.toastr.success(
          this.translateService.instant("widget.widgetAddedSuccessfully")
        );
        this.loadWidgets();
      },
      (): void => {
        this.toastr.error(
          this.translateService.instant("widget.failedToAddWidget")
        );
      }
    );
  }

  public updateWidget({ id, widget }: UpdateWidget): void {
    this.widgetsService.updateWidget(id, widget).subscribe(
      (): void => {
        this.toastr.success(
          this.translateService.instant("widget.widgetUpdatedSuccessfully")
        );
        this.loadWidgets();
      },
      (): void => {
        this.toastr.error(
          this.translateService.instant("widget.failedToUpdateWidget")
        );
      }
    );
  }

  public deleteWidget(widgetId: number): void {
    this.widgetsService.deleteWidget(widgetId).subscribe(
      (): void => {
        this.toastr.success(
          this.translateService.instant("widget.widgetDeletedSuccessfully")
        );
        this.loadWidgets();
      },
      (): void => {
        this.toastr.error(
          this.translateService.instant("widget.failedToDeleteWidget")
        );
      }
    );
  }

  private loadWidgets(onlyFilter = false): void {
    const url = this.widgetsService.getUrl('widgets/');
    this.cacheService.delete(url);
    console.log(url);
    if (onlyFilter) {
      this.filterWidgets();
    } else {
      this.widgetsService
        .getWidgets()
        .subscribe((widgets: ResponseModel<WidgetModel[]>): void => {
          this.widgets = widgets.model;
          this.filteredWidgets = widgets.model;
          this.filterWidgets();
        });
    }
  }

  private filterWidgets(): void {
    const dict = ['bgColor', 'sizeLarge', 'sizeMedium', 'sizeSmall', 'name', 'settings', 'nameT', 'view', 'fgColor', 'type'];
      this.widgets = this.widgets.map(obj => { 
      obj.config={};
      for (const key of dict) {
         obj.config[key] = obj[key];
      }
        return obj;
      });
        this.filteredWidgets = this.widgets.filter(
      (widget: WidgetModel): boolean => {
        if (this.filterLotIds.length > 0) {
          return (
            widget.contentType === "lot" &&
            this.filterLotIds.includes(widget.objectId)
          );
        }
        if (this.filterFarmIds.length > 0) {
          if (widget.contentType === "lot") {
            const matchingLot: LotModel =
              this.globalRegistry.systemData.lots.find(
                (lot: LotModel): boolean => lot.id === widget.objectId
              );
            return this.filterFarmIds.includes(matchingLot.farm);
          }
          if (widget.contentType === "farm") {
            return this.filterFarmIds.includes(widget.objectId);
          }
          return false; // reached this line, filters applied, content type other than lot or farm => filter out
        }
        return true; // no filters to apply, show everything
      }
    );
    console.log(this.filteredWidgets);
  }

  private initMap(): void {
    // if (window.innerWidth >= 767) {
    //   this.mapService.showMap();
    // }
    this.mapService.resetMap();
    this.mapService.isEditMode = false;
    const farmPolygons: Array<MapPolygonInterface> = this.filteredFarms.map(
      (farm: FarmModel): MapPolygonInterface => ({
        points: MapHelper.convertToAGMPolygon(farm.coords.coordinates[0]),
        strokeColor: "#a31f1f",
        fillColor: "#de3333",
      })
    );
    const lotPolygons: Array<MapPolygonInterface> = this.filteredLots.map(
      (lot: LotModel): MapPolygonInterface => ({
        points: MapHelper.convertToAGMPolygon(lot.coords.coordinates[0]),
        fillColor: "#ffff00",
        strokeColor: "#ffff00",
      })
    );
    this.mapService.mapPolygons = [...farmPolygons, ...lotPolygons];
    this.mapService.centerMapOnPolygons();
  }

  private getFilteredFarms(farmIds: number[], lotIds: number[]): FarmModel[] {
    if (
      (!farmIds || farmIds.length === 0) &&
      (!lotIds || lotIds.length === 0)
    ) {
      return this.globalRegistry.systemData.farms;
    }

    return this.globalRegistry.systemData.farms.filter(
      (farm: FarmModel): boolean =>
        farmIds.some((farmId: number): boolean => farmId === farm.id)
    );
  }

  private getFilteredLots(farmIds: number[], lotIds: number[]): LotModel[] {
    if (
      (!farmIds || farmIds.length === 0) &&
      (!lotIds || lotIds.length === 0)
    ) {
      return this.globalRegistry.systemData.lots;
    }
    let filteredLots: LotModel[] = [];
    if (lotIds && lotIds.length > 0) {
      filteredLots = this.globalRegistry.systemData.lots.filter(
        (lot: LotModel): boolean =>
          lotIds.some((lotId: number): boolean => lotId === lot.id)
      );
    } else {
      filteredLots = this.globalRegistry.systemData.lots.filter(
        (lot: LotModel): boolean => farmIds.includes(lot.farm)
      );
    }
    return filteredLots;
  }

  public getFormTypes() {
    const url = this.typeService.getUrl('form/');
    this.cacheService.delete(url);
    this.typeService.getFormTypes().subscribe((data) => {
        this.formTypes = data.body.results;
        console.log(data.body.results);
    });
  }

  public updateSensor(sensorId: number) {
    this.subscriptions.push(this.sensorService.getSensor(sensorId).subscribe(data => {
      const index = this.sensors.findIndex(sensor => sensor.id === sensorId);
      if (index !== -1) {
        this.sensors[index] = data.model;
      }    
    }))
  }
}
