import { Pipe, PipeTransform } from '@angular/core';
import { UnitTypeModel } from '../services/api/unit-type/unit-type.service';

@Pipe({
    pure: false,
    name: 'getUnitType',
})
export class getUnitTypePipe implements PipeTransform {
    public transform(unit: any, unitsMap: any): any {
    
        if (!unitsMap) {
          return "?"
        }
    
        let unitType: UnitTypeModel
        unitType = unitsMap.get(unit);
        return unitType ? unitType.shortName : '';
        }
}
