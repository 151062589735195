import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { FapModalComponent } from '../../../../../shared/partials';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { CacheResolverService } from '../../../../../core/services/api/cache/cache-resolver.service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { NavService } from '../../../../../shared/services/nav.service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { ResponseModel } from '../../../../../core/models/response.model';
import { DeviceModel, ObjectModel, ObjectService } from '../../../../../core/services/api/objects/object.service';
import { UnitTypeModel, UnitTypeService } from '../../../../../core/services/api/unit-type/unit-type.service';
import { SensorModel } from '../../../../../core/models/sensor/sensor.model';
import { SensorService } from '../../../../../core/services/api/sensor/sensor.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'pools-layout',
    templateUrl: './pools-layout.component.html',
    styleUrls: ['./pools-layout.component.scss'],
})
export class PoolsLayoutComponent implements OnInit {
    @Input() pools = [];
    public subscriptions: Array<Subscription> = [];
    public entityInfo: any;
    public translatedNames:any = [];
    public langString: string;
    @ViewChild('addEditPoolModal')
    public addEditPoolModal: FapModalComponent;
    public subscription: Subscription = new Subscription();
    objects: ObjectModel[] = [];
    unitTypes: UnitTypeModel[] = [];
    sensors: SensorModel[] = [];
    devices: DeviceModel[] = [];
    objectId: number;

    constructor(
        public activatedRoute: ActivatedRoute,
        public typesService: TypesService,
        public cacheService: CacheResolverService,
        public router: Router,
        public widgetService: WidgetsService,
        public navService: NavService,
        public globalRegistry: GlobalRegistryService,
        public ObjectService: ObjectService,
        public unitTypeService: UnitTypeService,
        public sensorService: SensorService,
        public objectService: ObjectService,
        public toastr: ToastrService
    ) {}

    ngOnInit(): void {
        this.getWidgets();
        this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
        this.translatedNames = this.globalRegistry.systemData.translations;

        this.subscriptions.push(
          combineLatest([
            this.ObjectService.getObjectTypes(),
            this.unitTypeService.getUnits(),
            this.sensorService.getSensors(),
            this.objectService.getDevices()
          ]).subscribe(
            ([objectTypes, unitTypes, sensors, devices]: [
              ResponseModel<ObjectModel[]>,
              ResponseModel<UnitTypeModel[]>,
              ResponseModel<SensorModel[]>,
              ResponseModel<DeviceModel[]>,
            ]): void => {
              this.objects = objectTypes.model;
              this.unitTypes = unitTypes.model;
              this.sensors = sensors.model;
              this.devices = devices.model;
            }))
    }

    getWidgets() {
      console.log("method called")
        const url = this.widgetService.getUrl('widgets/');
        this.cacheService.delete(url + 'unique_type=pool');
        const param = {
          unique_type: 'pool',
        };
        this.widgetService.getWidgetView(param).subscribe((data) => {
            this.pools = data.model;
            console.log(this.pools);
            if(!this.pools.length) {
              this.getFormTypes();
            }
        });
    }

    onUpdateAction() {
      this.updateSensors();
      this.globalRegistry.reloadTranslations();
      this.getWidgets();
    }

    showError() {
      this.toastr.error('Cannot edit widget in this view!');
      return;
    }

    updateSensors() {
      const url = this.sensorService.getUrl("sensors/");
      this.cacheService.delete(url);
      this.sensorService.getSensors().subscribe(data => {
        this.sensors = data.model;
      })
    }

    public getFormTypes() {
        const url = this.typesService.getUrl('form/');
        this.cacheService.delete(url + 'uniqueid=POOL_CREATE');
        const params = { uniqueid: 'POOL_CREATE' };
        this.typesService.getFormTypes(params).subscribe((data) => {
            console.log(data);
            this.showPoolsModal();
            this.entityInfo = data.body.results[0];
        });
    }

    resetObjectId() {
      this.redirectBack();
      this.objectId = null;
      this.entityInfo = null
    }

    redirectBack() {
        this.router.navigate(['pages/pools']);
    }

    showPoolsModal() {
      this.addEditPoolModal.showModal();
      this.objectId = 0;
    }

    hidePoolsModal() {
      this.addEditPoolModal.hideModal();
      this.redirectBack();
      this.objectId = null;
      this.entityInfo = null
    }

    getTranslation(translation) {
      const t =this.translatedNames.filter(trans => {
        return trans.id === translation
      });
      if(t[0]) {
        if(t[0][this.langString] === null || t[0][this.langString] === '') {
          return translation
        } else {
          return t[0][this.langString];
        }
      } else {
          return translation
        }
    }

    deletePool(widget) {
      console.log(widget);
      this.widgetService.deleteWidget(widget.id).subscribe(data => {
        console.log(data);
        this.getWidgets();
      }, (error) => {
        this.toastr.error(error.error.results.error);
      })
    }
}
