import { AttributeService } from './../services/api/attribute/attribute.service';
import { ForecastService } from './../services/api/forecast/forecast.service';
import { EventEmitter, Injectable } from '@angular/core';

import { SystemDataInterface } from './system-data.interface';
import { UserModel } from '../models/user/user.model';
import { FarmService } from '../services/api/farm/farm.service';
import { ResponseModel } from '../models/response.model';
import { FarmModel } from '../models/farm/farm.model';
import { CropTypeModel } from '../models/type/crop-type.model';
import { TypesService } from '../services/api/types/types.service';
import { UserService } from '../services/api/user/user.service';
import { PartyService } from '../services/api/company/party.service';
import { PartyModel } from '../models/party/party.model';
import { CompanyService } from '../services/api/company/company.service';
import { PersonModel } from '../models/person/person.model';
import { PeopleGroupModel } from '../models/groups/people-group.model';
import { PeopleGroupService } from '../services/api/people-group/people-group.service';
import {
    Observable,
    combineLatest,
    BehaviorSubject,
    Subject
} from 'rxjs';
import { take } from 'rxjs/operators';
import { LotService } from '../services/api/farm/lot.service';
import { LotModel } from '../models/lot/lot.model';
import { EquipmentTypeModel } from '../models/type/equipment-type.model';
import { AttributeModel } from '../models/attribute/attribute.model';
import { AttributeRelationModel } from '../models/attribute/attribute-relation.model';
import { NotesService } from '../services/api/farm/notes.service';
import { NoteModel } from '../models/notes/note.model';
import { FieldService } from '../services/api/farm/field.service';
import { FieldModel } from '../models/field/field.model';
import { StockService } from '../services/api/stock/stock.service';
import { TransactionModel } from '../models/stock/transaction.model';
import { ImageModel } from '../models/company/image.model';
import { AddressModel } from '../models/company/address.model';
import { IngredientModel } from '../models/stock/ingredient.model';
import { CacheResolverService } from '../services/api/cache/cache-resolver.service';
import { PlanModel } from '../models/company/plan.model';
import { UnitTypeModel, UnitTypeService } from '../services/api/unit-type/unit-type.service';

@Injectable()
export class GlobalRegistryService {

    public userData: UserModel;
    public systemData: SystemDataInterface;
    public translationData;

    public genres = [
        {
            indicator: 'm',
            name: 'Length'
        },
        {
            indicator: 'kg',
            name: 'Mass'
        },
        {
            indicator: 'hour',
            name: 'Time'
        },
        {
            indicator: 'power',
            name: 'Power'
        },
        {
            indicator: 'sqm',
            name: 'Area'
        },
        {
            indicator: 'mc',
            name: 'Volume'
        },
        {
            indicator: 'I',
            name: 'Electric current'
        },
        {
            indicator: 'V',
            name: 'Electric Voltage'
        },
        {
            indicator: 'ohm',
            name: 'Electrical resistance'
        },
        {
            indicator: 'Pa',
            name: 'Pressure'
        },
        {
            indicator: 'T',
            name: 'Temperature'
        },
        {
            indicator: '$',
            name: 'Currency'
        },
        {
            indicator: 'pkg',
            name: 'Packaging'
        },
    ]

    /**
     * @todo this should be moved to toolbar service or something like that
     */
    public selectedDate: string;

    // Show precision dropdown
    public showPrecisionFilters = false;

    public isLoading = true;
    public isDataLoaded = false;

    public precisionType: EventEmitter<string> = new EventEmitter<string>();
    public precisionDate: EventEmitter<string> = new EventEmitter<string>();
    public precisionDatesList: EventEmitter<number> = new EventEmitter<number>();

    public newFarmOrLotAdded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public addLocalRelationsToService: Subject<number> = new Subject<number>();

    private _isLoggedIn = false;


    constructor(private farmService: FarmService,
                private lotService: LotService,
                private typesService: TypesService,
                private userService: UserService,
                private partyService: PartyService,
                private companyService: CompanyService,
                private noteService: NotesService,
                private peopleGroupService: PeopleGroupService,
                private forecastService: ForecastService,
                private unitService: UnitTypeService,
                private fieldService: FieldService,
                private attributeService: AttributeService,
                private stockService:StockService,
                public cacheService: CacheResolverService
                ) {
        this.systemData = {};
    }

    public get isLoggedIn(): boolean {
        return this._isLoggedIn;
    }

    public set isLoggedIn(value: boolean) {
        this._isLoggedIn = value;

        if (!value) {
            console.warn('redirect user to login');
        }
    }

    public initUserData(user: UserModel): void {
        this.userData = user;
    }

    public initSystemData(systemData: SystemDataInterface): void {
        this.systemData = systemData;
    }

    public iniTranslations(translation: any): void {
        this.translationData = translation;
    }

    public reloadImages(callback?: () => void): void {
        const url = this.companyService.getUrl('images/');
        this.cacheService.delete(url);
        this.companyService.getCompanyImages().subscribe((response: ResponseModel<ImageModel[]>): void => {
            this.systemData.images = response.model;
        })
        if(callback) {
            callback();
        }
    }

    public reloadTranslations(callback?: () => void): void {
        const url = this.typesService.getUrl('translate/');
        this.cacheService.delete(url);
        this.typesService.getTranslations().subscribe((response: ResponseModel<any[]>): void => {
            this.systemData.translations = response.model;
            this.systemData.translationsMap = new Map<number, any[]>();
            this.createObjectMap(response.model, this.systemData.translationsMap);
            if (callback) {
                callback();
            }
        });
    }

    public reloadFarms(callback?: () => void): void {
        const url = this.farmService.getUrl('farm/');
        this.cacheService.delete(url);
        this.farmService.getFarms().subscribe((response: ResponseModel<FarmModel[]>): void => {
            this.systemData.farms = response.model;
            // this.reloadForecasts();
            if (callback) {
                callback();
            }
        });
    }

    public reloadCropTypes(callback?: () => void): void {
        const url = this.typesService.getUrl('crop/');
        this.cacheService.delete(url);
        this.typesService.getCropTypes().subscribe((response: ResponseModel<CropTypeModel[]>): void => {
            this.systemData.cropTypes = response.model;
            if (callback) {
                callback();
            }
        });
    }

    public reloadIngredientsTypes(callback?: () => void): void {
        const url = this.typesService.getUrl('ingredient/');
        this.cacheService.delete(url);
        this.typesService.getIngredientTypes().subscribe((response: ResponseModel<CropTypeModel[]>): void => {
            this.systemData.ingredientTypes = response.model;
            if (callback) {
                callback();
            }
        });
    }

    public reloadIngredients(callback?: () => void): void {
        const url = this.stockService.getUrl('ingredients/');
        this.cacheService.delete(url);
        this.stockService.getIngredients({limit: 1000}).subscribe((response: ResponseModel<IngredientModel[]>): void => {
            this.systemData.ingredients = response.body.results;
            if (callback) {
                callback();
            }
        });
    }

    public reloadResourceTypes(callback?: () => void): void {
        const url = this.typesService.getUrl('resource/');
        this.cacheService.delete(url);
        this.typesService.getResourceTypes().subscribe((response: ResponseModel<any[]>): void => {
            this.systemData.resourceTypes = response.body.results;
            if (callback) {
                callback();
            }
        });
    }

    public reloadEquipmentTypes(callback?: () => void): void {
        const url = this.typesService.getUrl('equipment/');
        this.cacheService.delete(url);
        this.typesService.getEquipmentTypes().subscribe((response: ResponseModel<EquipmentTypeModel[]>): void => {
            this.systemData.equipmentTypes = response.model;
            if (callback) {
                callback();
            }
        });
    }

    public reloadPersons(callback?: () => void): void {
        const url = this.companyService.getUrl('persons/');
        this.cacheService.delete(url);
        this.companyService.getPersons({limit: 1000}).subscribe((response: ResponseModel<PersonModel[]>): void => {
            this.systemData.persons = response.body.results;
            console.log(this.systemData.persons);
            if (callback) {
                callback();
            }
        });
    }

    public reloadPlans(callback?: () => void): void {
        const url = this.companyService.getUrl('plan/');
        this.cacheService.delete(url);
        this.companyService.getPlans().subscribe((response: ResponseModel<PlanModel[]>): void => {
            this.systemData.plans = response.model;
            console.log(this.systemData.plans);
            if (callback) {
                callback();
            }
        });
    }

    public reloadNotes(callback?: () => void): void {
        const url = this.noteService.getUrl('');
        this.cacheService.delete(url);
        this.noteService.getNotes().subscribe((response: ResponseModel<NoteModel[]>): void => {
            this.systemData.notes = response.model;
            if (callback) {
                callback();
            }
        });
    }

    public reloadFields(callback?: () => void): void {
        const url = this.fieldService.getUrl('');
        this.cacheService.delete(url);
        this.fieldService.getFields().subscribe((response: ResponseModel<FieldModel[]>): void => {
            this.systemData.fields = response.model;
            if (callback) {
                callback();
            }
        });
    }

    public reloadPeopleGroups(callback?: () => void): void {
        const url = this.peopleGroupService.getUrl('');
        this.cacheService.delete(url);
        this.peopleGroupService.getPeopleGroups().subscribe((response: ResponseModel<PeopleGroupModel[]>): void => {
            this.systemData.peopleGroups = response.model;
            if (callback) {
                callback();
            }
        });
    }

    public reloadUserData(callback?: () => void): void {
        const url = this.userService.getUrl('');
        this.cacheService.delete(url);
        this.userService.getUser().subscribe((response: ResponseModel<UserModel>): void => {
            this.userData = response.model;
            if (callback) {
                callback();
            }
        });
    }

    public reloadParties(callback?: () => void): void {
        const url = this.partyService.getUrl('');
        this.cacheService.delete(url);
        this.partyService.getParties({limit: 20}).subscribe((response: ResponseModel<PartyModel[]>): void => {
            this.systemData.parties = response.body.results;
            if (callback) {
                callback();
            }
        });
    }

    public reloadAddresses(callback?: () => void): void {
        const url = this.companyService.getUrl('address/');
        this.cacheService.delete(url);
        this.companyService.getAddresses().subscribe((response: ResponseModel<AddressModel[]>): void => {
            this.systemData.addresses = response.model;
            if (callback) {
                callback();
            }
        });
    }

    public reloadForecasts(callback?: () => void): void {
        const forecastObservables: Observable<{}>[] = this.systemData.farms.map((farm: FarmModel): Observable<{}> => {
            return this.forecastService.reloadForecast('farm', farm.id);
        });
        combineLatest(forecastObservables).pipe(take(1)).subscribe((): void => {
            if (callback) {
                callback();
            }
        });
    }

    public reloadLots(callback?: () => void): void {
        const url = this.lotService.getUrl('');
        this.cacheService.delete(url);
        this.lotService.getLots().subscribe((response: ResponseModel<LotModel[]>): void => {
            this.systemData.lots = response.model;
            if (callback) {
                callback();
            }
        });
    }

    public reloadUnits(callback?: () => void): void {
        const url = this.unitService.getUrl('');
        this.cacheService.delete(url);
        this.unitService.getUnits().subscribe((response: ResponseModel<UnitTypeModel[]>): void => {
            this.systemData.units = response.model;
            this.systemData.unitsMap = new Map<number, any[]>();
            this.createObjectMap(response.model, this.systemData.unitsMap);
            if (callback) {
                callback();
            }
        });
    }

    public createObjectMap(arrayOfObjects: any[], objectMap: Map<number, any>): Map<number, any> {
        arrayOfObjects.forEach(obj => {
            objectMap.set(obj.id, obj);
            if(obj.children && obj.children.length) {
               this.createObjectMap(obj.children, objectMap);
            }
        });
        return objectMap;
    }

    public reloadAttributes(callback?: () => void): void {
        const url = this.typesService.getUrl('field/');
        this.cacheService.delete(url);
        this.typesService.getAttributes().subscribe((response: ResponseModel<AttributeModel[]>): void => {
            this.systemData.attributes = response.model;
            if (callback) {
                callback();
            }
        });
    }

    public reloadTransactions(callback?: () => void): void {
        const url = this.stockService.getUrl('transactions/');
        this.cacheService.delete(url);
        this.stockService.getTransactions().subscribe((response: ResponseModel<TransactionModel[]>): void => {
            this.systemData.transactions = response.model;
            if (callback) {
                callback();
            }
        });
    }

    public filterFarms(filter?: string): FarmModel[] {
        let farms: FarmModel[] = [];
        if (filter) {
            this.systemData.farms.forEach((option: FarmModel) => {
                if (option.name.toLowerCase().includes(filter.toLowerCase())) {
                    farms.push(option);
                }
            });
        } else {
            farms = this.systemData.farms;
        }
        return farms;
    }

    public getAttributeName(id: number): string {
        return this.systemData.attributes.find(
          (attrib: AttributeModel) => attrib.id === id
        )?.name;
      }

    public getAttribute(id: number): AttributeModel {
        let attribute: AttributeModel;
        this.systemData.attributes.forEach((attrib: AttributeModel) => {
            if (attrib.id === id) {
                attribute = attrib;
            }
        });
        return attribute;
    }

    public getAttributeRelation(attributeID: number, relations: AttributeRelationModel[]): number {
        console.log(relations, 'registry relations');
        const relation: AttributeRelationModel[] = relations.filter((model: AttributeRelationModel) => model.type === attributeID);
        console.log(relation, 'registry');
        return relation[0].id;
    }

    public deleteLocalAttributeRelation(relationModel): void {
        let attributeRelations: any[] = localStorage.getItem(
          "fap-attribute-relations"
        )
          ? JSON.parse(localStorage.getItem("fap-attribute-relations"))
          : [];
    
        attributeRelations = attributeRelations.filter((attributeRelation) => {
          return (
            attributeRelation["relation"]["attribute"] !== relationModel.attribute
          );
        });
        localStorage.setItem(
          "fap-attribute-relations",
          JSON.stringify(attributeRelations)
        );
        this.reloadAttributes(() => {
          this.attributeService.attributeRelations.next();
          const attributes = [];
          attributeRelations.forEach((attributeRelation) => {
            attributes.push(attributeRelation["attribute"]);
          });
          this.systemData.attributes.push(...attributes);
        });
      }

      public getAccessRights(key:string){
        const accessBoolean = [true,true];
        const accessObj = localStorage.getItem("access")?JSON.parse(localStorage.getItem("access")):{};
        const accessData:[number,number,number] = accessObj[key];
        if(accessData && accessData.length>0){
          accessBoolean[0] = accessData[1] === 1;
          accessBoolean[1] = accessData[2] === 1;
        }
        return accessBoolean;
      }

      getQueryStringParams = (query) => {
        return query
          ? (/^[?#]/.test(query) ? query.slice(1) : query)
              .split("&")
              .reduce((params, param) => {
                const [key, value] = param.split("=");
                params[key] = value
                  ? decodeURIComponent(value.replace(/\+/g, " "))
                  : "";
                return params;
              }, {})
          : {};
      };

      getLocalAttrAndRelations():number {
          const localAttributes:AttributeModel[] = localStorage.getItem("fap-attributes")?JSON.parse(localStorage.getItem("fap-attributes")):[];
          const localAttributeRelations:AttributeRelationModel[] = localStorage.getItem("fap-attribute-relations")?JSON.parse(localStorage.getItem("fap-attribute-relations")):[];
          const localDeleteRelations:AttributeRelationModel[] = localStorage.getItem("fap-delete-relations")?JSON.parse(localStorage.getItem("fap-delete-relations")):[];
          return localAttributes.length + localAttributeRelations.length + localDeleteRelations.length;
      }
}
