import { Pipe, PipeTransform } from '@angular/core';
import { UnitTypeModel } from '../services/api/unit-type/unit-type.service';

@Pipe({
    pure: false,
    name: 'getUnit',
})
export class getUnitPipe implements PipeTransform {
    public transform(unit: any, unitsMap: any): string {
    
        if (!unitsMap) {
          return "?"
        }
    
        let unitPack: UnitTypeModel;
        unitPack = unitsMap.get(unit);
        return unitPack && unitPack.shortName ? unitPack.shortName : '';
        }
}
