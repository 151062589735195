import { NoteTypeModel } from './../type/note-type.model';
import { FapBaseModel } from '../../base/fap-base-model';
import { LotModel } from '../lot/lot.model';
import { PersonModel } from '../../models/person/person.model';

export class NoteModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'name',
        'name_t',
        'type',
        'timestamp',
        'lots',
        'no_action',
        'until',
        'info',
        'active',
        'completed',
        'images',
        'assigned_to',
        'coords',
        'created_by',
        'parent',
        'plan'
    ];

    public static requiredFields: Array<string> = [
        'id',
        'type',
        'lots'
    ];

    public static extendedFields: {} = {
        type: NoteTypeModel,
        assignedTo: PersonModel
    };

    public id: number;
    public name: string;
    public nameT: string;
    public type: any;
    public timestamp: Date;
    public lots: LotModel[];
    public noAction: boolean;
    public until: Date;
    public info: string;
    public active: boolean;
    public completed?: boolean;
    public images: any;
    public assignedTo:PersonModel = new PersonModel();
    public coords: any;
    public createdBy:PersonModel = new PersonModel();
    public parent: any;
    public plan: any;
}
