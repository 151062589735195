import { ResourceEntryModel } from './resource-entry.model';
import { FapBaseModel } from '../../base/fap-base-model';
import { StockTransactionEnum } from './../../enums/stock-transaction.enum';
import { UnitTypeModel } from '../../services/api/unit-type/unit-type.service';


export class TransactionModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'created_by',
        'resource_entry',
        'created_at',
        'expiration_date',
        'cost',
        'quantity',
        'type',
        'unit'
    ];

    public static requiredFields: Array<string> = [
        'id',
        'created_by',
        'resource_entry',
        'expiration_date',
        'quantity',
        'type'
    ];

    public static extendedFields: {} = {
        resource_entry: ResourceEntryModel,
        unit: UnitTypeModel
    };

    public id: number;
    public createdBy: string;
    public resourceEntry: ResourceEntryModel = new ResourceEntryModel();
    public createdAt: string;
    public expirationDate: string;
    public cost: number;
    public quantity: number;
    public unit: UnitTypeModel = new UnitTypeModel();
    public type: StockTransactionEnum;
}
