import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { FapAPIRequestOptions } from "../fap-api-request.options";
import { ResponseModel } from "../../../models/response.model";
import { FapRestBaseService } from "../../../base/fap-rest-base.service";
import { FapBaseModel } from "../../../base/fap-base-model";

export class UnitTypeModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'name',
        'color',
        'aggrec_func',
        'icon',
        'locked',
        'name_t',
        'genre',
        'short_name',
        'children',
        'parent',
        'is_default'
    ];
    public static requiredFields: Array<string> = [
        'id',
    ];
    public static extendedFields: {} = {
        children: UnitTypeModel
    };
    public id: number;
    public name: string;
    public color: string;
    public aggrecFunc: string;
    public icon?: string;
    public locked: boolean;
    public nameT: string;
    public genre: string;
    public shortName: string;
    public children: Array<UnitTypeModel> = [];
    public parent: number;
    public isDefault: boolean;
}

@Injectable()
export class UnitTypeService extends FapRestBaseService {

  constructor(tosterService: ToastrService, private _http: HttpClient) {
    super(tosterService, _http, "/type/unit/");
  }

  getUrl(slug) {
    return this.url+slug
  }

  public getUnits(params?: {}): Observable<ResponseModel<UnitTypeModel[]>> {
    return this.mapRequest<UnitTypeModel[]>(
      this._http.get(this.url, {
        headers: FapAPIRequestOptions.withTokenHeaders,
        params,
      }),
      UnitTypeModel,
      true
    ).pipe(take(1));
  }

  public getUnit(unitId: number): Observable<ResponseModel<UnitTypeModel>> {
          return this.mapRequest<UnitTypeModel>(
                  this.http.get(this.url + unitId + '/',
                  FapAPIRequestOptions.withTokenRequestOptions
              ),
              UnitTypeModel
          );
      }
  
      public getUnitByGenre(genre: string): Observable<ResponseModel<UnitTypeModel[]>> {
          return this.mapRequest<UnitTypeModel[]>(
              this.http.get(this.url + '?genre=' + genre,
              FapAPIRequestOptions.withTokenRequestOptions
          ),
          UnitTypeModel,
          true
      );
      }

  public addUnit(unit: any): Observable<ResponseModel<UnitTypeModel>> {
          return this.mapRequest<UnitTypeModel>(
              this.http.post(this.url,
                  this.camelCaseModelToSnakeCaseJson(unit),
                  FapAPIRequestOptions.withTokenRequestOptions,
              ),
              UnitTypeModel
          ).pipe(take(1));
      }
  
      public updateUnit(unit: any): Observable<any> {
          return this.mapRequest(
              this.http.patch(this.url + unit.id + '/',
                  this.camelCaseModelToSnakeCaseJson(unit),
                  FapAPIRequestOptions.withTokenRequestOptions
              )
          ).pipe(take(1));
      }
  
      public deleteUnit(unitId: number): Observable<ResponseModel<{}>> {
          return this.mapRequest(
              this.http.delete(this.url + unitId + '/',
                  FapAPIRequestOptions.withTokenRequestOptions
              )).pipe(take(1));
      }
}
