import {
    Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { TranslationModel } from '../../../../core/models/type/translation-type.model';
import { UnitValueModel } from '../../../../core/models/units/unit-value.model';
import { FapModalComponent } from '../../../../shared/partials';
import { ConfirmModalService } from '../../../../shared/services/confirm-modal.service.ts/confirm-modal.service';
import { NavService } from '../../../../shared/services/nav.service';
import { UnitTypeModel, UnitTypeService } from '../../../../core/services/api/unit-type/unit-type.service';

@Component({
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FapUnitValueComponent,
            multi: true
        }
    ],
    selector: 'fap-unit-value',
    templateUrl: './fap_unit-value.component.html',
    styleUrls: ['./fap_unit-value.component.scss']
})
export class FapUnitValueComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges{

    @Input()
    public fieldName: string;

    @Input()
    public previewMode = false;

    @ViewChild('addEditUnitModal')
    public addEditUnitModal: FapModalComponent;

    @Input()
    public filter: string;

    public selectedUnit: UnitTypeModel;
    public parentUnit: UnitTypeModel;
    public selectedUnitId: number;
    public unitValue: UnitValueModel = {} as UnitValueModel;
    public translatedNames: Array<TranslationModel>;
    public langString: string;
    public subscription: Subscription = new Subscription();
    public units: Array<UnitTypeModel> = [];

    constructor(public globalRegistry: GlobalRegistryService,
                private translateService: TranslateService,
                private unitService: UnitTypeService,
                private toastyService: ToastrService,
                private confirmModalService: ConfirmModalService,
                private navService:NavService) {
    }

    public ngOnInit(): void {
        this.langString = localStorage.getItem('language');
      this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
        if(lang) {
          this.langString = lang;
        }
    }));
    this.translatedNames = this.globalRegistry.systemData.translations;
    this.units = this.globalRegistry.systemData.units;
    }

    public writeValue(unitValue: UnitValueModel): void {
        this.unitValue = unitValue || {} as UnitValueModel;
        if(unitValue) {
            this.selectedUnit = this.getUnit(unitValue.unit);
        }
    }

    public registerOnChange(fn: () => any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn): void {
        console.log("registered on touch", fn)
     }

     // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public propagateChange = (_: any): void => {
        console.log('propagate change method called')
     };

    public valueChanged(): void {
        this.propagateChange(this.unitValue);
    }

    public selectUnit(unit?: UnitTypeModel, parent?:  UnitTypeModel): void {
        if(unit?.isDefault) {
            return;
        }

        this.selectedUnit = unit;

        this.parentUnit = parent ? parent : null;
        this.addEditUnitModal.showModal();
    }

    public onUnitAction(unitId: number): void {
        this.selectedUnitId = unitId;
        this.addEditUnitModal.hideModal();
    }

    public onDeleteUnit(unit: UnitTypeModel): void {
        if(unit.isDefault) {
            return;
        }

        this.confirmModalService.openConfirmModal(
            this.translateService.instant('confirmDelete'),
            this.translateService.instant('units.deleteUnitConfirmMessage')
        );
        this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
            if (confirmed) {
                this.deleteUnit(unit.id);
            }
        });
    }

    public deleteUnit(unitId: number): void {
        this.unitService.deleteUnit(unitId).subscribe({
          next: (): void => {
            this.toastyService.success(this.translateService.instant('units.unitDeleteSuccessfull'));
            this.globalRegistry.reloadUnits();
          },
          error: (): void => {
            this.toastyService.error(this.translateService.instant('units.UnitDeleteError'));
          }
        });
      }
      

    private getUnit(unitId: number): UnitTypeModel {
        return this.globalRegistry.systemData.units.find((unit: UnitTypeModel) => {
            return unit.id === unitId;
        });
    }

    getTranslation(translation) {
        const t =this.translatedNames.filter(trans => {
          return trans.id === translation
        });
        if(t[0]) {
            if(t[0][this.langString] === null || t[0][this.langString] === '') {
              return translation
            } else {
              return t[0][this.langString];
            }
          } else {
            return translation
        }
      }

      
      public ngOnDestroy(): void {
        this.subscription.unsubscribe()
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'filter')){
           this.unitService.getUnitByGenre(this.filter).subscribe(data => {
            this.units = data.model;
           });
        }
    }
}
